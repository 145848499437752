/* eslint-disable prettier/prettier */

import { useState } from "react"
import icon from "../../../assets/icons/contact.svg"
import { Button } from "antd"

const faqData = [
  {
    question: "Can I register my business for free?",
    answer:
      "Yes, you can register your business for free with us. We do not charge any amount for onboarding, however, we take charges on transactions. You can find out more by reading our policy or contacting us.",
  },
  {
    question: "Can I get help at any time?",
    answer:
      "Yes, you can get help anytime with Translite. Our commitment to customer support team is always available to answer your requests and render assistance with any issues you may have. ",
  },
  {
    question: "What services does translite offer?",
    answer: `Translite offers a comprehensive suite of services designed to streamline and optimise your transaction processing needs. Our services include: Transaction Processing, Payment Gateway Integration, Fraud Prevention, Reporting and Analytics, Customisation and Configuration, and Dedicated Technical Support. 
    \n
    We are your trusted partner for all your transaction processing needs, providing reliable services and unparalleled support to help your business thrive.`,
  },
  {
    question: "Do I need any documents to register?",
    answer:
      "No, you don't need any extensive documentation to register with Translite. Our Know Your Customer (KYC) requirements are straightforward and hassle-free. Simply provide the personal details of the business owner and relevant information about your business (in the case of registered businesses), and you're all set to go. We understand the importance of simplicity and efficiency, which is why we've kept our registration process straightforward. ",
  },
  {
    question: "How can i get more about translite?",
    answer:
      "For more information about Translite and our offerings, please feel free to contact us anytime. Our dedicated team is available to provide you with comprehensive details about our services, answer any questions you may have, and assist you in finding the perfect payment solution for your business needs. At Translite, innovation is at the heart of what we do. Even when we're not actively solving a payment problem, we're constantly brainstorming and strategizing to anticipate and address the next challenge in the payment landscape. Let us architect your next payment solution and propel your business forward into the future of seamless transactions. Schedule a consultation session with us today, and together we can narrow down how Translite can revolutionize your payment processing experience.",
  },
]
const Faqs: React.FC = () => {
  const [openIndex, setOpenIndex] = useState<number | null>(null)

  const toggleFAQ = (index: number) => {
    if (openIndex === index) {
      setOpenIndex(null)
    } else {
      setOpenIndex(index)
    }
  }

  return (
    <div
      className="flex flex-col items-center justify-center gap-10 px-2 py-8"
      id="faqs"
    >
      <div>
        <p className="text-base font-semibold text-center md:text-2xl">
          Frequently asked questions
        </p>
        <p className=" text-[#353454] font-normal text-sm md:text-xl text-center">
          We’ve provided answers to some questions you may have.
        </p>
      </div>
      <div className="w-full px-2 md:w-2/3 mb-10">
        {faqData.map((faq, index) => (
          <div key={index} className="border-b border-black">
            <button
              className="flex items-center justify-between w-full p-3 xsm:p-6 focus:outline-none"
              onClick={() => toggleFAQ(index)}
            >
              <h2 className="text-sm font-medium md:text-lg text-[#101028]">
                {faq.question}
              </h2>
              <div className=" border-[#9899B3] border-[1.5px] rounded-[50%] text-[#9899B3] sm:w-6 sm:h-6 flex items-center justify-center w-4 h-4 ">
                {openIndex === index ? "-" : "+"}
              </div>
            </button>
            <div className={`p-4 ${openIndex === index ? "block" : "hidden"}`}>
              <p className="text-[#353454] font-normal md:text-base -mt-7 ml-2 text-xs text-justify leading-loose">
                {faq.answer}
              </p>
            </div>
          </div>
        ))}
      </div>
      <div
        className="bg-[#F9F9FB] rounded-2xl w-[80%] flex flex-col justify-center items-center md:py-8 py-4 gap-2"
        id="contact-us"
      >
        <img src={icon} alt="" className="" />
        <p className="text-base font-semibold md:text-xl">
          Still need some help?
        </p>
        <p className="text-[#353454] font-normal text-xs md:text-lg text-center">
          Can’t find the answers you’re looking for? Please contact our team.
        </p>
        <a href="mailto:tegritechng@gmail.com">
          <Button className="text-white hover:text-[white!important] bg-[#6D71F9] px-4 py-5  items-center flex sm:text-base font-semibold rounded-lg text-sm">
            Contact us
          </Button>
        </a>
      </div>
    </div>
  )
}

export default Faqs
