/* eslint-disable prettier/prettier */
import processor from "../../../assets/icons/secureprocessor.svg"
import swiftProcess from "../../../assets/icons/swift-process.svg"
import configurationApproach from "../../../assets/icons/configuration-approach.svg"
import integrity from "../../../assets/icons/intergrity.svg"
import hassleFree from "../../../assets/icons/hassle-free.svg"
import commited from "../../../assets/icons/commited.svg"
const offerItems = [
  {
    key: "0",
    image: processor,
    title: "Secured processor",
    description:
      " Our state-of-the-art technology ensures that your transactions are processed securely to boost your business to new heights",
  },
  {
    key: "1",
    image: swiftProcess,
    title: "Swift transaction processing",
    description:
      " Elevate your business with a transaction processor that is hassle-free and swift, to keep your profitability intact.",
  },
  {
    key: "2",
    image: configurationApproach,
    title: "Configuration-centric approach",
    description:
      " Our configuration approach enables you to specify the routing of your transactions, ensuring optimal  profitability and success rates.",
  },
  {
    key: "3",
    image: integrity,
    title: "Integrity and transparency",
    description:
      "Every transaction being processed on our platform conforms to high ethical standards, ensuring that your operations are conducted with credibility.",
  },
  {
    key: "4",
    image: hassleFree,
    title: "Hassle-free experience",
    description:
      " Streamlined processes and user-centric interfaces make transactions swift, allowing you to focus on what matters most –– growing your business.",
  },
  {
    key: "5",
    image: commited,
    title: "We are committed to your success",
    description:
      "Our team is always ready to support your business in anyway you might need assistance for the smooth operation of your business.",
  },
]

const Offers: React.FC = () => {
  return (
    <div className="px-2 space-y-20" id="about">
      <div className="flex flex-col items-center justify-center gap-1 md:gap-2">
        <p className=" text-[#6D71F9] text-base font-semibold">What we offer</p>
        <p className="text-base font-semibold text-center md:text-2xl">
          Optimised terminals for your business
        </p>
        <p className="text-sm font-normal text-center md:text-base md:max-w-[50%] max-w-full">
          Inspired by the need for a solution that puts your business at the
          forefront, our dedicated team has crafted a platform that revolves
          around your success.
        </p>
      </div>
      <div className="flex flex-wrap items-center justify-center w-full gap-2 md:gap-6">
        {offerItems.map((item) => (
          <div className="flex flex-col items-center justify-center w-[70%] gap-5 mb-10 md:w-1/4 ">
            <span className="border border-[#D0D0DD] rounded-xl object-contain flex justify-center items-center h-12 w-12 mt-4 xsm:mt-0">
              <img src={item.image} alt={item.title} />
            </span>
            <p className="text-sm font-semibold text-center md:text-base">
              {item.title}
            </p>
            <p className="text-xs font-normal text-center md:text-sm text-[#484767] ">
              {item.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  )
}

export default Offers
