/* eslint-disable prettier/prettier */

import Faqs from "./components/faqs"
import Footer from "./components/footer"
import Home from "./components/home"
import Offers from "./components/offers"
import Review from "./components/review"


const Homepage: React.FC = () => {
  document.title = "Translite | Seamless payments for businesses"
  return <div className="font-[inter] space-y-11 ">
    <Home/>
    <Offers/>
    <Review/>
    <Faqs/>
    <Footer/>
  </div>
}

export default Homepage