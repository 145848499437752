/* eslint-disable prettier/prettier */

import Header from "./header"
import backgroundImage from "../../../assets/images/backgroundImage.png"
import playDemo from "../../../assets/icons/play.svg"
import { Button } from "antd"
import laptop from "../../../assets/images/laptop.png"
import { ROUTE } from "@common/constants"
import { useNavigate } from "react-router-dom"

const Home: React.FC = () => {
  const navigate = useNavigate()
  return (
    <div
      className="relative flex flex-col gap-6 px-2 mb-20 bg-center bg-cover md:gap-10"
      id="home"
    >
      <img
        src={backgroundImage}
        alt=""
        className=" absolute z-[-1] h-full w-full opacity-[16%] object-cover"
        loading="lazy"
      />

      <Header />
      <div className="grid gap-4 place-items-center">
        <p className="border border-[#D0D0DD] rounded-full text-xs md:text-sm font-medium flex items-center gap-1 justify-center p-2 px-3 whitespace-nowrap">
          Experience speed and security
        </p>
        <h1 className="text-2xl font-semibold text-center md:text-6xl">
          Seamless payments for businesses
        </h1>
        <p className="text-sm font-normal text-center md:text-base md:max-w-[50%] max-w-full">
          Inspired by the need for a solution that puts your business at the
          forefront, our dedicated team has crafted a platform that revolves
          around your success.
        </p>
        <div className="flex flex-col-reverse w-full gap-3 text-base font-semibold xxsm:flex-row xxsm:justify-center">
          <a href="mailto:tegritechng@gmail.com">
            <Button className=" text-[#353454] border border-[#D0D0DD] shadow-none outline-none px-4 py-5 flex gap-2 items-center font-medium  hover:!text-black  hover:!outline-none w-full justify-center">
              <img src={playDemo} alt="" />
              Request a demo
            </Button>
          </a>
          <Button
            className="text-white  bg-[#6D71F9] px-4 py-5  items-center flex text-base font-semibold rounded-lg hover:!text-white hover:!border-none hover:!outline-none justify-center border-none  "
            onClick={() => navigate(ROUTE.LOGIN)}
          >
            Log in
          </Button>
        </div>
      </div>
      <div className="flex flex-col items-center justify-center object-contain w-full">
        <img className="w-[60rem]" src={laptop} alt="" />
        <hr className="w-full " />
      </div>
    </div>
  )
}

export default Home
