/* eslint-disable prettier/prettier */
import { Button } from "antd"
import logo from "../../../assets/images/Logo.png"
import { ROUTE } from "@common/constants"
import { Link, useNavigate } from "react-router-dom"
import menu from "../../../assets/icons/menu.svg"
import close from "../../../assets/icons/Close.svg"
import { useState } from "react"

const scrollToElement = (id: string) => {
  // Prevent the default anchor link behavior
  const element = document.getElementById(id)
  if (element) {
    element.scrollIntoView({ behavior: "smooth", block: "start" })
  }
}

const Header: React.FC = () => {
  const navigate = useNavigate()
  const [menuOpen, setMenuOpen] = useState(false)
  return (
    <div className="flex items-center justify-between w-full px-2 py-4 xsm:gap-8 xsm:relative">
      <img src={logo} alt="" className="w-auto h-8" />
      <button className=" xsm:hidden" onClick={() => setMenuOpen(!menuOpen)}>
        {menuOpen ? (
          <img src={close} alt="Close" className="w-6 h-6" />
        ) : (
          <img src={menu} alt="Menu" className="w-6 h-6" />
        )}
      </button>
      <ul
        className={`${
          menuOpen
            ? "flex flex-col bg-white  absolute top-12 right-1 z-[2]  gap-2  w-full "
            : "hidden"
        } xsm:w-full xsm:flex xsm:flex-row xsm:justify-between xsm:bg-inherit xsm:top-auto xsm:right-auto xsm:static xsm:p-0 xsm:rounded-none transition-all duration-300 ease-in-out xsm:gap-10 `}
      >
        <div className="flex flex-col items-center w-full gap-3 xsm:gap-12 xsm:text-base xsm:flex-row h-[100svh] xsm:h-10 pt-3 xsm:pt-0">
          <li className=" border-b-[#656565] border-b w-full xsm:w-max text-center py-3 text-base font-normal text-black xsm:text-[#484767] xsm:font-semibold xsm:border-b-0 xsm:py-0">
            <Link
              to="/"
              onClick={(e) => {
                e.preventDefault()
                scrollToElement("home")
              }}
            >
              Home
            </Link>
          </li>
          <li className=" border-b-[#656565] border-b w-full text-center py-3 text-base font-normal text-black xsm:text-[#484767] xsm:font-semibold xsm:border-b-0 xsm:py-0 xsm:w-max">
            <Link
              to="/"
              onClick={(e) => {
                e.preventDefault()
                scrollToElement("faqs")
              }}
            >
              FAQs
            </Link>
          </li>
          <li className=" border-b-[#656565] border-b w-full text-center py-3 text-base font-normal text-black xsm:text-[#484767] xsm:font-semibold xsm:border-b-0 xsm:py-0 xsm:w-max">
            <Link
              to="/"
              onClick={(e) => {
                e.preventDefault()
                scrollToElement("about")
              }}
            >
              About us
            </Link>
          </li>
          <li className=" border-b-[#656565] border-b w-full text-center py-3 text-base font-normal text-black xsm:text-[#484767] xsm:font-semibold xsm:border-b-0 xsm:py-0 xsm:w-max">
            <Link
              to="/"
              onClick={(e) => {
                e.preventDefault()
                scrollToElement("contact-us")
              }}
            >
              Contact us
            </Link>
          </li>
        </div>
        <div className="flex flex-col items-center gap-3 text-base font-semibold xsm:flex-row ">
          <a href="mailto:tegritechng@gmail.com">
            <Button className="text-base font-semibold border-none outline-none shadow-none hover:!text-black hover:!border-none hover:!outline-none hidden xsm:flex">
              Request a demo
            </Button>
          </a>
          <Button
            className="text-white  bg-[#6D71F9] px-4 py-5 items-center  text-base font-semibold rounded-lg hover:!text-white hover:!outline-none hidden xsm:flex  border-none"
            onClick={() => navigate(ROUTE.LOGIN)}
          >
            Log in
          </Button>
        </div>
      </ul>
    </div>
  )
}

export default Header
