/* eslint-disable prettier/prettier */
import userIcon from "../../../assets/images/user.png"
const Review: React.FC = () => {
  return (
    <div className=" bg-[#F9F9FB] flex flex-col justify-center items-center py-12 gap-10 px-2">
      <p className="text-lg font-normal text-center md:text-3xl w-full md:max-w-[70%] leading-loose">
        We’ve been using Translite to process transactions <br />& configure our
        terminals for proper management.
      </p>
      <span className="flex flex-col items-center mt-5">
        <img src={userIcon} alt="" className=" rounded-[50%] w-16 h-16" />
        <p className="text-base font-semibold md:text-lg">Fikayomi Iwalade</p>
        <p className=" text-[#353454] text-xs md:text-sm font-normal">Entrepreneur</p>
      </span>
    </div>
  )
}

export default Review
