/* eslint-disable prettier/prettier */
import logo from "../../../assets/images/Logo.png"

const Footer: React.FC = () => {
  return (
    <div className="flex items-center justify-center py-10">
      <div className="border-t border-[#D0D0DD] w-[80%] flex xsm:justify-between xsm:items-center flex-col justify-start xsm:flex-row items-start">
        <img src={logo} alt="" className="w-auto h-8 mt-5" />
        <p className="text-[#353454] text-base mt-5">
          © Translite 2024. All rights reserved.
        </p>
      </div>
    </div>
  )
}

export default Footer
